<template>
  <div class="relative flex h-screen flex-1 flex-col overflow-y-hidden bg-gray-900 font-medium text-white antialiased">
    <AdminNavbar v-bind="auth" />
    <slot />
    <ModalConfirmation />
    <NotificationBase :flash-messages="flashMessages" />
  </div>
</template>
<script setup>
import { onMounted, computed, onBeforeMount } from 'vue';
import AdminNavbar from '@/components/AdminNavbar.vue';
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue';
import { usePage } from '@inertiajs/vue3';
import { useStore } from 'vuex';
import { createConsumer } from '@rails/actioncable';
import { flashMessage } from '@/composeables/helpers';
import NotificationBase from '@/components/notification/NotificationBase.vue';

const $page = usePage();
const $store = useStore();

onBeforeMount(async () => {
  await $store.dispatch('loadAdminAppData');
  await $store.dispatch('setAdminCoinsAndDatapoints');
});

onMounted(() => {
  subscribeChannels();
});

const flashMessages = computed(() => $page.props['flash-messages']);

const cable = computed(() => {
  const host = window.location.hostname;
  const protocol = host.includes('localhost') ? 'ws://' : 'wss://';
  const port = host.includes('localhost') ? ':8080' : '';
  return createConsumer(`${protocol + host + port}/cable`);
});

function subscribeChannels() {
  const socket = new WebSocket(cable.value.url);
  socket.addEventListener('error', event => {
    // eslint-disable-next-line
    console.log('WebSocket error', event);
  });
  socket.addEventListener('open', async () => {
    await subscribeToUserNotifications();
  });
}

const subscribeToUserNotifications = () => {
  return new Promise((resolve, reject) => {
    cable.value.subscriptions.create('UserNotifyChannel', {
      connected: function () {
        // eslint-disable-next-line
        console.log('connected to UserNotifyChannel', new Date());
        resolve();
      },
      disconnected: function () {},
      received: function (event) {
        if (event.message) {
          let payload = {
            type: event.type || 'notice',
            message: event.message
          };
          flashMessage(payload);
        }
      }
    });
  });
};

const auth = computed(() => $page.props.auth);
</script>
